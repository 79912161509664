import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "UserManagement";

export const getAllUsersUrl = (filter) =>
	`${baseUrl}/allusers?${generateUrlParams(filter)}`;
export const createUserUrl = () => `${baseUrl}/create-user`;
export const editUserUrl = (id) => `${baseUrl}/update-user?userId=${id}`;
export const toggleUserStatusUrl = (id) =>
	`${baseUrl}/toggle-user-status?userId=${id}`;
export const getUserProfileUrl = () => `${baseUrl}`;
export const updateUserProfileUrl = () => `${baseUrl}/update-user`;
export const getUsersUrl = (filter) =>
	`${baseUrl}?${generateUrlParams(filter)}`;