import { useMemo } from "react";
import { Route } from "react-router-dom";
import { TabLayout } from "../../ui_elements";

export const ReportsRouter = ({
	component,
	path,
	exact,
	purpose,
	title,
	user,
	...rest
}) => {
	const Component = component;
	const paths = useMemo(
		() => [
			{
				title: "Sundry Reports",
				path: "/reports/sundry",
				disabled: false
			},
			{
				title: "Student Fee History",
				path: "/reports/school_fees",
				disabled: false
			},
			{
				title: "Payment Report",
				path: "/reports/payments",
				disabled: false
			},
			{
				title: "Admission List Report",
				path: "/reports/admission_list",
				disabled: false
			},
			{
				title: "Hostel Reports",
				path: "/reports/hostel_reports",
				disabled: false
			},
			{
				title: "Lecturer Result Report",
				path: "/reports/lecturer_result",
				disabled: false
			},
			{
				title: "Hostel Summary Reports",
				path: "/reports/hostel_summary_reports",
				disabled: false
			}
		],
		[]
	);
	return (
		<Route
			exact={exact}
			path={path}
			{...rest}
			render={(props) => {
				return (
					<TabLayout title={title} paths={paths}>
						<Component {...rest} {...props} />
					</TabLayout>
				);
			}}
		/>
	);
};
