const baseUrl = "Select";

export const getAllUnpaginatedRolesUrl = () => `${baseUrl}/all-roles`;
export const getAllBursaryFeeInvoiceTypesUrl = () =>
	`${baseUrl}/bursary-fee-invoice-types`;
export const getAllServiceTypesUrl = () => `${baseUrl}/service-types`;
export const getOLevelExamTypesUrl = () => `${baseUrl}/o-level-exam-types`;
export const getOLevelSubjectsUrl = () => `${baseUrl}/o-level-subjects`;
export const getYearsUrl = () => `${baseUrl}/exam-year`;
export const getApplicationTypesUrl = () => `${baseUrl}/application-types`;
export const getJupebApplicationTypesUrl = () =>
	`${baseUrl}/jupeb-application-types`;
export const getUtmeSubjects = () => `${baseUrl}/utme-subjects`;
export const getPGProgrammesUrl = () => `${baseUrl}/pg-programmes`;
export const getBursaryFeeInvoiceTypesUrl = () =>
	`${baseUrl}/bursary-fee-invoice-types`;
export const getAllDepartmentsWithoutValuesUrl = () => `${baseUrl}/departments`;
export const getAllSelectLevels = () => `${baseUrl}/all-levels`;
