import React, { useState, createRef, createContext } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import useAuthAction from "../../custom-hooks/useAuthAction";
import Logout from "../../pages/shared/Logout/logout";
import {
	TENECE_SUPPORT_URL,
	TOKEN_HOLDER,
	USER_CONDITION_HOLDER,
	USER_NAME_HOLDER,
	USER_ROLE_HOLDER
} from "../../utils/constants";
import {
	GlobalMenu,
	SideMenu,
	Banner,
	PageLoader,
	AccessDenied,
	Button
} from "../index";
import "./layout.css";
import { getStudentProfileUrl } from "../../api/urls";
import { useApiGet } from "../../api/apiCall";
import { StrongWarning, Warning } from "../../assets/svgs";

export const parent = createRef(null);

export const StudentProfileContext = createContext();

let menuItems = [];
const paths = {
	dashboard: "/dashboard",
	applications: "/applications/setup",
	"approve courses": "/approve_courses",
	"course management": "/course_management/manage_course",
	"fees assignment": "/fees_assignment/school_fees",
	"invoice management": "/invoice_management/invoice",
	payments: "/payments",
	"lecturer management": "/lecturer_management/lecturer_upload",
	"notice board setup": "/notice_board_management",
	"my class": "/class",
	"my courses": "/records",
	reports: "/reports/sundry",
	"result management": "/results/classlist",
	"student management": "/student_management/search_student",
	"user management": "/user_management/users",
	"application reports": "/app_reports/application_reports",
	"hostel management": "/hostel_management/manage_hostel",
	// student specific links
	acceptance: "/acceptance",
	hostel: "/hostel",
	"course registration": "/course_registration",
	"school fees": "/school_fees",
	results: "/results",
	"payment history": "/payment_history"
};

const Layout = ({ children, title, noHeader }) => {
	const [open, setOpen] = useState(false);
	const [signOutModal, setSignOutModal] = useState(false);
	const { logout } = useAuthAction();
	const [cookies] = useCookies([
		TOKEN_HOLDER,
		USER_ROLE_HOLDER,
		USER_NAME_HOLDER,
		USER_CONDITION_HOLDER
	]);
	const menuItemsFromApi = useSelector((state) => state.menuItemsData);

	const { data, isLoading, error } = useApiGet(
		getStudentProfileUrl({ refCode: false }),
		{
			refetchOnWindowFocus: false,
			enabled: cookies[USER_ROLE_HOLDER] === "student"
		}
	);
	const { tokenExpirationDate, impersonatorUsername, isImpersonating } =
		useSelector((state) => state.impersonatorDetails);
	const {
		[USER_NAME_HOLDER]: userName,
		[USER_CONDITION_HOLDER]: userCondition
	} = cookies;
	menuItems = menuItemsFromApi
		.map((item) => ({
			name: item,
			path: paths[item.toLowerCase()]
		}))
		.filter((item) => item.path);

	const userConditions = {
		suspended: {
			condition: "suspended",
			title: "You have been suspended 😔",
			icon: StrongWarning,
			text: "Your academic journey is temporarily on hold; let's work together to ensure a smooth return once it's over."
		},
		expelled: {
			condition: "expelled",
			title: "You have been Expelled 😭",
			icon: StrongWarning,
			text: "Your enrollment has been terminated; please reach out if you have questions or need assistance."
		},
		withdrawn: {
			condition: "withdrawn",
			title: "Your admission has been Withdrawn😭",
			icon: StrongWarning,
			text: "We regret to inform you that your admission has been withdrawn; please contact us for guidance on finding new opportunities."
		},
		deferred: {
			condition: "deferred",
			title: "Your admission has been Deferred 👌",
			icon: Warning,
			text: "Your academic journey is temporarily on hold; let's work together to ensure a smooth return once it's over."
		}
	};

	if (isLoading) {
		return <PageLoader />;
	}

	if (error) {
		return "An error has occurred: " + error?.message;
	}
	return (
		<StudentProfileContext.Provider value={{ profileData: data?.data }}>
			<div className="app-container">
				<section className="res-header">
					{isImpersonating && (
						<Banner
							impersonator={impersonatorUsername}
							impersonated={userName}
							date={tokenExpirationDate}
							onEndSessionClick={logout}
						/>
					)}
					<GlobalMenu
						title={title}
						openSide={() => setOpen(true)}
						setSignOutModal={setSignOutModal}
						userName={userName}
					/>
				</section>
				<section className={`${open ? "res-side" : "res-no-side"}`}>
					<div
						className="res-side-menu--background"
						onClick={() => setOpen(false)}
					></div>
					<SideMenu
						paths={menuItems}
						closeSide={() => setOpen(false)}
						setSignOutModal={setSignOutModal}
					/>
				</section>
				<div
					className={`children ${noHeader ? "no-header-tab" : ""}`}
					ref={parent}
					style={isImpersonating ? { paddingTop: "96px" } : {}}
				>
					{!!userConditions[userCondition] ? (
						<AccessDenied
							text={userConditions[userCondition]?.text}
							title={userConditions[userCondition]?.title}
							Icon={userConditions[userCondition]?.icon}
							buttonGroup={
								<a
									href={TENECE_SUPPORT_URL}
									target="_blank"
									rel="noreferrer"
								>
									<Button
										label="Contact support"
										buttonClass="primary"
									/>
								</a>
							}
						/>
					) : (
						<>{children}</>
					)}
				</div>
				<Logout
					isOpen={signOutModal}
					closeModal={() => setSignOutModal(false)}
				/>
			</div>
		</StudentProfileContext.Provider>
	);
};

export { Layout };
